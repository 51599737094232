import { DocumentType, Language, Work } from "@biblioteksentralen/cordata";
import { FrontendLocale } from "@libry-content/localization";
import { useEffect } from "react";
import { z } from "zod";
import { useMultipleUrlStates } from "../../../../utils/hooks/useMultipleUrlStates/useMultipleUrlStates";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { isMovie } from "../../../cordata/workTypes";
import {
  ExternalContentService,
  getExternalContentService,
  supportedExternalContentServices,
} from "../../reservation/externalContentService";
import { IdentifierForDocumentType, getIdentifiersForDocumentType } from "../../reservation/MovieReservation";
import { getDocumentTypesInLanguage } from "../../reservation/ReservationLanguageMenu";
import {
  CordataWork,
  getLanguagesSignature,
  toCordataLanguageCode,
  WorkTripleIdentifier,
} from "@libry-content/integrations";

export const variantParameterSchema = z.object({
  language: z.string().optional(),
  type: z.string().optional(),
  isbn: z.string().optional(),
  manifestationId: z.string().optional(),
  externalContentService: z.string().optional(),
});

export type VariantParameters = z.infer<typeof variantParameterSchema>;
export const useCurrentVariantState = () => useMultipleUrlStates(variantParameterSchema);

const chooseLanguage = (
  languages: Language[][],
  languageCodeParameter: string | undefined,
  siteLanguageCode: FrontendLocale
) => {
  return (
    languages.find((languageList) => getLanguagesSignature(languageList) === languageCodeParameter) ??
    languages.find(
      (languageList) => languageList.length === 1 && languageList[0]?.code === toCordataLanguageCode(siteLanguageCode)
    ) ??
    languages.find((languageList) =>
      languageList.some(({ code }) => code === toCordataLanguageCode(siteLanguageCode))
    ) ??
    //finding language list with no elements, because this means one of the valid choices for language is no language (empty language array is the case e.g. for most audio music variants, such as CD).
    //An undefined languageCodeParameter might mean a variant with no languages, so here we need to pick the empty array.
    languages.find((languageList) => languageList.length === 0) ??
    languages[0]
  );
};

export const useCurrentVariantCharacteristics = (work: Work) => {
  const [currentVariantState, updateCurrentVariantState] = useCurrentVariantState();

  const cordataWork = new CordataWork(work);
  const languages = cordataWork.getAvailableWorkLanguages();
  const documentTypes = cordataWork.getDocumentTypes();

  const { currentDocumentType, currentExternalContentService, currentLanguagesList } = useCurrentVariantStateData(work);

  const currentLanguagesSignature =
    currentLanguagesList && currentLanguagesList.length > 0 ? getLanguagesSignature(currentLanguagesList) : undefined;

  // TODO: Can we move this closer to reservation logic?
  // If the value from URL query parameters is not available we auto update to the chosen fallback value
  useEffect(() => {
    const languageParameter =
      !!currentLanguagesSignature &&
      !!currentVariantState?.language &&
      !!(currentLanguagesSignature !== currentVariantState.language)
        ? { languageParameter: currentLanguagesSignature }
        : {};

    const documentTypeParameter =
      !!currentDocumentType?.code &&
      !!currentVariantState?.type &&
      !!(currentDocumentType.code !== currentVariantState.type)
        ? { documentTypeParameter: currentDocumentType.code }
        : {};

    const externalContentServiceParameter =
      !!currentExternalContentService &&
      !!currentVariantState?.externalContentService &&
      !!(currentExternalContentService.originName !== currentVariantState.externalContentService)
        ? { externalContentServiceParameter: currentExternalContentService.originName }
        : {};

    const variantParameters: VariantParameters = {
      isbn: undefined,
      manifestationId: undefined,
      ...languageParameter,
      ...documentTypeParameter,
      ...externalContentServiceParameter,
    };

    if (Object.values(variantParameters).some((value) => value)) updateCurrentVariantState(variantParameters);
  }, [
    currentLanguagesSignature,
    currentDocumentType,
    currentExternalContentService,
    currentVariantState?.language,
    currentVariantState?.type,
    currentVariantState?.externalContentService,
    updateCurrentVariantState,
  ]);

  return {
    currentDocumentType,
    currentLanguagesList,
    currentLanguagesSignature,
    currentExternalContentService,
    currentVariantState,
    updateCurrentVariantState,
    languages,
    documentTypes,
  };
};

type CurrentVariantStateData = {
  currentDocumentType?: DocumentType;
  currentLanguagesList?: Language[];
  currentExternalContentService?: ExternalContentService;
};

export const useCurrentVariantStateData = (work: Work): CurrentVariantStateData => {
  const { lang } = useTranslation();
  const [currentVariantState] = useCurrentVariantState();

  const cordataWork = new CordataWork(work);
  const languages = cordataWork.getAvailableWorkLanguages();
  const documentTypes = cordataWork.getDocumentTypes();

  const identifier: WorkTripleIdentifier | undefined =
    (currentVariantState?.isbn && { isbn: currentVariantState.isbn }) ||
    (currentVariantState?.manifestationId && { manifestationId: currentVariantState?.manifestationId }) ||
    undefined;

  if (identifier) {
    const workTriple = cordataWork.getWorkTriple(identifier);
    if (workTriple)
      return {
        currentDocumentType: workTriple?.documentType,
        currentLanguagesList: workTriple?.languages,
        currentExternalContentService: getExternalContentService(workTriple?.origin),
      };
  }

  // For movies the order of choice is oppsite of the usual: format -> language.
  if (isMovie(work)) {
    const currentDocumentType =
      documentTypes.find((documentType) => documentType.code === currentVariantState?.type) ?? documentTypes[0];
    const currentDocumentTypeCode = currentDocumentType?.code;
    const documentTypeIsExternalService = isExternalService(currentDocumentType?.code);
    const availableIdentifiers: IdentifierForDocumentType[] =
      isMovie(work) && documentTypeIsExternalService && currentDocumentTypeCode
        ? getIdentifiersForDocumentType(work, currentDocumentTypeCode)
        : languages.map((languageMap) => ({ languages: languageMap }));
    const currentLanguagesList = chooseLanguage(
      availableIdentifiers.map((availableIdentifiersMap) => availableIdentifiersMap.languages),
      currentVariantState?.language,
      lang
    );
    const currentExternalContentService = documentTypeIsExternalService
      ? (
          availableIdentifiers.find(
            (availableIdentifiersMap) =>
              availableIdentifiersMap.externalContentService?.originName === currentVariantState?.externalContentService
          ) ?? availableIdentifiers.find((availableIdentifiersMap) => availableIdentifiersMap.externalContentService)
        )?.externalContentService
      : undefined;

    return {
      currentDocumentType,
      currentLanguagesList,
      currentExternalContentService,
    };
  }

  const currentLanguagesList = chooseLanguage(languages, currentVariantState?.language, lang);

  const documentTypeFromVariantState = documentTypes.find(
    (documentType) => documentType.code === currentVariantState?.type
  );

  const documentTypesFromVariantStateInLanguage = getDocumentTypesInLanguage(
    work,
    getLanguagesSignature(currentLanguagesList ?? [])
  );

  const documentTypeFromVariantStateInLanguage =
    documentTypesFromVariantStateInLanguage.find((documentType) => documentType.code === currentVariantState?.type) ??
    //Picking first in array if no type in url state, as elements in this array will be valid
    documentTypesFromVariantStateInLanguage[0];

  const currentDocumentType =
    documentTypeFromVariantState ?? documentTypeFromVariantStateInLanguage ?? documentTypes[0];

  return {
    currentDocumentType,
    currentLanguagesList,
  };
};

const isExternalService = (documentTypeCode: string | undefined) =>
  !!documentTypeCode &&
  !!supportedExternalContentServices.find(({ documentTypeCode }) => documentTypeCode === documentTypeCode);
