import { Work } from "@biblioteksentralen/cordata";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { getAgeGroups } from "../../../cordata/ageGroups";
import {
  getRepresentativeWorkContributors,
  getRepresentativeWorkMainContributors,
} from "../../../cordata/contributors";
import { getGenres } from "../../../cordata/genres";
import {
  WorkToBeRepresented,
  getFormattedRepresentativeManifestationTitle,
  getRepresentativeManifestation,
} from "../../../cordata/manifestations";
import { getOriginalLanguageTitle } from "../../../cordata/titles";
import { useCurrentVariantCharacteristics } from "./useCurrentVariantCharacteristics";

// TODO: Can this be split into several to avoid duplicate processing?
export const useWorkViewData = (work: Work) => {
  const { t, ts } = useTranslation();

  const {
    currentDocumentType,
    currentLanguagesList,
    currentLanguagesSignature,
    currentExternalContentService,
    currentVariantState,
    updateCurrentVariantState,
    languages,
    documentTypes,
  } = useCurrentVariantCharacteristics(work);

  const workWithRepresentation: WorkToBeRepresented = {
    ...work,
    representativeLanguages: currentLanguagesSignature,
    representativeFormat: currentDocumentType?.format,
  };

  const representativeManifestation = getRepresentativeManifestation(workWithRepresentation);
  const representativeExpressionForInteractiveWork = work.expressions?.find(
    ({ id }) => id === representativeManifestation?.expressions?.[0]?.expressionId
  );
  const formattedTitle = getFormattedRepresentativeManifestationTitle(work, representativeManifestation);
  const originalLanguageTitle = getOriginalLanguageTitle(work, representativeManifestation);
  const coverImage = representativeManifestation?.coverImage;
  const contributors = getRepresentativeWorkContributors(work, representativeManifestation);
  const mainContributors = getRepresentativeWorkMainContributors(work, representativeManifestation);
  const formGenres = getGenres(ts, work, { isForm: true });
  const nonFormGenres = getGenres(ts, work, { isForm: false });
  const ageGroups = getAgeGroups(t, ts, work);

  return {
    work,
    formattedTitle,
    originalLanguageTitle,
    coverImage,
    contributors,
    mainContributors,
    languages,
    documentTypes,
    formGenres,
    nonFormGenres,
    ageGroups,
    currentVariantState,
    updateCurrentVariantState,
    currentLanguagesList,
    currentLanguagesSignature,
    currentDocumentType,
    currentExternalContentService,
    collections: representativeManifestation?.expression.collections,
    representativeManifestation,
    representativeExpressionForInteractiveWork,
  };
};
