import { Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import {
  Alert,
  Box,
  Button,
  FormLabel,
  HStack,
  Heading,
  ModalBody,
  ModalFooter,
  RadioProps,
  Select,
  Spinner,
  Text,
  VStack,
  colors,
  useRadio,
  useRadioGroup,
} from "@biblioteksentralen/react";
import { AtLeastOne } from "@biblioteksentralen/types";
import { getErrorMessage } from "@libry-content/common";
import { FormEventHandler, RefObject, SetStateAction, useCallback, useEffect, useState } from "react";
import { ModalContainer } from "../../../components/ModalContainer";
import { LoginForm } from "../../../rediaPlatform/LoginForm";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { sortManifestationsByRelevance } from "../../cordata/manifestations";
import { useLibrarySystemBranches } from "../../hooks/useLibrarySystemBranches";
import { useSearchConfig } from "../../hooks/useSearchConfig";
import { ManifestationStatusForUser as ManifestationStatusForUserType } from "../manifestations/useManifestationStatusForUser";
import { ReservationModalAlreadyOnLoan } from "./ReservationModalAlreadyOnLoan";
import { ReservationModalSuccess } from "./ReservationModalSuccess";
import { ReservationModalWorkPreview } from "./ReservationModalWorkPreview";
import { useChosenReservableHolding } from "./useChosenReservableHolding";
import { useSelectedLibrary } from "./useSelectedLibrary";
import { SortableManifestation } from "../../types";
import { HoldingCounts } from "../../hooks/useHoldingCountsForManifestations";
import InternalLink from "../../../components/InternalLink";
import { WorkCoverImage } from "../WorkCoverImage";
import { getDotseparated } from "../getDotseparated";
import { HoldingText } from "../HoldingText";
import { isNumber } from "radash";
import { getPartedAndWholeManifestations } from "./utils";
import { useSitePalette } from "../../../utils/useSitePalette";
import { usePickupLocations } from "../../../components/minside/dataFetchers/useLocations";

const noop = <T,>(item: T): T => item;

type CreateReservationStatus = "ready" | "loading" | "success" | "error";

type ReservationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  work: Work;
  manifestations: AtLeastOne<SortableManifestation>;
  representativeManifestationId?: string;
  manifestationStatusForUser: ManifestationStatusForUserType;
  onCloseFocusRef: RefObject<HTMLDivElement>;
  holdingCounts?: HoldingCounts;
};

export const ReservationModal = ({
  isOpen,
  onClose,
  work,
  manifestations,
  manifestationStatusForUser,
  representativeManifestationId,
  onCloseFocusRef,
  holdingCounts,
}: ReservationModalProps) => {
  const { t } = useTranslation();
  const { isSearchIntegrationEnabled } = useSearchConfig();
  const { rediaPlatform, user, isSessionReady } = useRediaPlatformContext();
  const isAvailable = holdingCounts?.availableForLoan !== 0;

  const {
    isilCodeFromBranchCode,
    branchCodeFromIsilCode,
    error: branchesError,
    isLoading: branchesLoading,
  } = useLibrarySystemBranches();

  const {
    onReservationsUpdate,
    isReservedByUser,
    isLoanedByUser,
    isLoading: userManifestationStatusLoading,
    isReady: userManifestationStatusReady,
    error: userManifestationStatusError,
  } = manifestationStatusForUser;

  const [createReservationStatus, setCreateReservationStatus] = useState<CreateReservationStatus>("ready");

  useEffect(() => {
    if (isOpen) setCreateReservationStatus("ready");
  }, [isOpen]);

  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
      if (createReservationStatus !== "error") setCreateReservationStatus("error");
    },
    [createReservationStatus]
  );

  const pickupLocations = usePickupLocations();
  const { selectedLibrary, setSelectedLibrary, selectedLibraryLoading } = useSelectedLibrary(pickupLocations, user);

  useEffect(() => {
    if (!user || branchesLoading || selectedLibraryLoading || selectedLibrary || pickupLocations.length > 1) return;
    handleError("Could not select library although only one is available");
  }, [branchesLoading, handleError, selectedLibraryLoading, selectedLibrary, pickupLocations.length, user]);

  // Should be the same for all manifestations in this context
  const documentType = manifestations.find(({ documentType }) => documentType?.format)?.documentType;
  if (!documentType?.format) handleError(`Can't find format from ${JSON.stringify(manifestations)}`);

  const partedAndWholeManifestations = getPartedAndWholeManifestations(work, manifestations);
  const [chosenManifestation, setChosenManifestation] = useState<SortableManifestation | undefined>(undefined);
  const choosingHoldingFromSpecificManifestation = !!chosenManifestation;
  const manifestationsToChooseHoldingFrom = chosenManifestation
    ? ([chosenManifestation] as AtLeastOne<SortableManifestation>)
    : manifestations;

  const manifestationSorter = documentType?.format
    ? sortManifestationsByRelevance(work.title, undefined, [documentType.format])
    : noop;

  const chosenReservableHolding = useChosenReservableHolding(
    pickupLocations,
    manifestationSorter,
    work,
    manifestationsToChooseHoldingFrom,
    selectedLibrary,
    isilCodeFromBranchCode
  );

  const userPickingManifestationFlow =
    partedAndWholeManifestations &&
    partedAndWholeManifestations.partedManifestations.length + partedAndWholeManifestations.wholeManifestations.length >
      1;

  const letUserPickManifestation = userPickingManifestationFlow && !chosenManifestation;

  const isBook = chosenReservableHolding?.manifestation.documentType?.format === "Bok";

  const onSubmit = async () => {
    if (!rediaPlatform || !branchCodeFromIsilCode || !onReservationsUpdate || !isSearchIntegrationEnabled) {
      return handleError("Missing setup for reservation");
    }

    if (!onReservationsUpdate) {
      return handleError("Missing callback onReservationsUpdate");
    }

    if (typeof selectedLibrary?.code !== "string") {
      return handleError(`Attempted to reserve from library: ${JSON.stringify(selectedLibrary)}`);
    }

    if (!chosenReservableHolding) {
      return handleError(`Could not select item and manifestation from ${JSON.stringify(manifestations)}`);
    }

    const selectedBranchCode = selectedLibrary.code;
    const { reserveId } = chosenReservableHolding || {};

    if (!selectedBranchCode || !reserveId) {
      const missing = [
        [selectedBranchCode, "branch code"],
        [reserveId, "reserve id"],
      ].filter(([variable]) => !variable);

      return handleError(`Could not set up reservation; missing ${missing.join(" and ")}`);
    }

    setCreateReservationStatus("loading");

    try {
      const reservation = await rediaPlatform.createReservation(reserveId, selectedBranchCode);

      if (!reservation) return handleError("Something went wrong when creating reservation");

      console.info("Succesfully created reservation", { reservation });
      setCreateReservationStatus("success");
      onReservationsUpdate();
    } catch (err) {
      handleError(err);
    }
  };

  const handleClose = () => {
    setCreateReservationStatus("ready");
    setChosenManifestation(undefined);
    onClose();
  };

  const loading =
    !isSessionReady ||
    !rediaPlatform ||
    branchesLoading ||
    selectedLibraryLoading ||
    createReservationStatus === "loading" ||
    userManifestationStatusLoading ||
    (user && !userManifestationStatusReady);

  if (loading) {
    return (
      <ModalContainer
        modalContentProps={{ height: "18rem" }}
        heading={t("Reserver")}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <Spinner
          position="absolute"
          top="calc(50% - 1rem)"
          left="calc(50% - 0.5rem)"
          transform="translate(-50%, -50%)"
        />
      </ModalContainer>
    );
  }

  if (isSessionReady && !user) {
    return (
      <ModalContainer heading={t("Reserver")} isOpen={isOpen} onClose={handleClose} finalFocusRef={onCloseFocusRef}>
        <LoginForm />
      </ModalContainer>
    );
  }

  if (createReservationStatus === "error" || branchesError || userManifestationStatusError) {
    return (
      <ModalContainer heading={t("Reserver")} isOpen={isOpen} onClose={handleClose}>
        <ModalBody padding="1rem 0.5rem">
          {t("Beklager, det oppsto en ukjent feil.")} {t("Feilen er logget og vil snart bli fikset.")}
        </ModalBody>
      </ModalContainer>
    );
  }

  // Sometimes the representative manifestation used in the work view banner is different than that of the reserved manifestation.
  // In that case we don't use the image in the preview here, to avoid confusion.
  //In the case of picking a specific manifestation (with parted manifestations), we do not hide the preview image.
  const manifestationToBeReservedIsDifferentThanRepresentative =
    !!representativeManifestationId && representativeManifestationId !== chosenReservableHolding?.manifestation.id;

  if (createReservationStatus === "success" || (!userPickingManifestationFlow && isReservedByUser)) {
    return (
      <ModalContainer
        heading={t("Reservasjonen din er registrert")}
        isOpen={isOpen}
        onClose={handleClose}
        modalContentProps={{ maxWidth: "30rem" }}
      >
        <ModalBody padding="1rem 0.5rem" display="flex" flexDirection="column" justifyContent="center">
          <ReservationModalSuccess
            work={work}
            reservableHolding={chosenReservableHolding!}
            hideImage={
              manifestationToBeReservedIsDifferentThanRepresentative && !choosingHoldingFromSpecificManifestation
            }
            selectedLibrary={selectedLibrary!}
            holdingCounts={holdingCounts}
          />
        </ModalBody>
        <ModalFooter padding="1rem 0.5rem">
          <HStack justifyContent="center" width="100%">
            {!isAvailable && isBook && (
              <Button as={InternalLink} href={`/samling/mensduventerpaa/${work.id}`} variant="secondary">
                {t("Se lignende bøker")}
              </Button>
            )}
            {isAvailable ? (
              <Button onClick={handleClose}>{t("OK")}</Button>
            ) : (
              <Button onClick={handleClose}>{isBook ? t("Ferdig") : t("OK")}</Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContainer>
    );
  }

  // This to cover the edge case where the user already has loaned the book but was not logged in when clicking
  // the reserve button, so that this fact was unknown
  if (!letUserPickManifestation && isLoanedByUser) {
    return (
      <ModalContainer
        heading={t("Reserver")}
        isOpen={isOpen}
        onClose={handleClose}
        modalContentProps={{ maxWidth: "26rem" }}
      >
        <ModalBody padding="1rem 0.5rem" display="flex" flexDirection="column" justifyContent="center">
          <ReservationModalAlreadyOnLoan
            work={work}
            reservableHolding={chosenReservableHolding!}
            hideImage={manifestationToBeReservedIsDifferentThanRepresentative}
          />
        </ModalBody>
        <ModalFooter padding="1rem 0.5rem">
          <HStack justifyContent="center" width="100%">
            <Button onClick={handleClose}>{t("OK")}</Button>
          </HStack>
        </ModalFooter>
      </ModalContainer>
    );
  }

  if (letUserPickManifestation) {
    return (
      <PickPartedOrWholeManifestationModal
        work={work}
        manifestationsWithPartNumber={partedAndWholeManifestations.partedManifestations}
        manifestationsWithoutPartNumber={partedAndWholeManifestations.wholeManifestations}
        manifestationStatusForUser={manifestationStatusForUser}
        setChosenPartedManifestation={setChosenManifestation}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    );
  }

  return (
    <ModalContainer heading={t("Reserver")} isOpen={isOpen} onClose={handleClose}>
      <VStack alignItems="flex-start" spacing="1.5rem">
        {!!chosenReservableHolding && (
          <ReservationModalWorkPreview
            work={work}
            reservableHolding={chosenReservableHolding}
            hideImage={
              manifestationToBeReservedIsDifferentThanRepresentative && !choosingHoldingFromSpecificManifestation
            }
          />
        )}
        {pickupLocations.length > 1 && (
          <FormLabel width="100%">
            <Text fontWeight="semibold" fontSize="sm">
              {t("Velg hentested")}
            </Text>
            <Select
              marginTop="0.25rem"
              defaultValue={selectedLibrary?.code}
              onChange={(event) =>
                setSelectedLibrary(pickupLocations.find(({ code }) => event.currentTarget?.value === code))
              }
            >
              {pickupLocations.map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
          </FormLabel>
        )}
      </VStack>
      <ModalFooter padding="2rem 0.5rem">
        <HStack justifyContent="space-between" width="100%">
          <Button variant="outline" onClick={handleClose}>
            {t("Avbryt")}
          </Button>
          <Button onClick={onSubmit}>{t("Reserver")}</Button>
        </HStack>
      </ModalFooter>
    </ModalContainer>
  );
};

const PickPartedOrWholeManifestationModal = ({
  work,
  manifestationsWithPartNumber,
  manifestationsWithoutPartNumber,
  manifestationStatusForUser,
  setChosenPartedManifestation,
  isOpen,
  handleClose,
}: {
  work: Work;
  manifestationsWithPartNumber: (SortableManifestation & { partNumber: string })[];
  manifestationsWithoutPartNumber: SortableManifestation[];
  manifestationStatusForUser: ManifestationStatusForUserType;
  setChosenPartedManifestation: (value: SetStateAction<SortableManifestation | undefined>) => void;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const [chosenManifestation, setChosenManifestation] = useState<SortableManifestation | undefined>(
    [...manifestationsWithoutPartNumber, ...manifestationsWithPartNumber].find((manifestation) =>
      isManifestationReservable(manifestation, manifestationStatusForUser)
    )
  );

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "Utgave",
  });

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    if (!chosenManifestation) return;
    setChosenPartedManifestation(chosenManifestation);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalContainer
        heading={t("Reserver")}
        modalContentProps={{ maxH: "40rem" }}
        footer={
          <HStack justifyContent="space-between" width="100%">
            <Button variant="outline" onClick={handleClose}>
              {t("Avbryt")}
            </Button>
            <Button
              type="submit"
              isDisabled={
                !chosenManifestation || !isManifestationReservable(chosenManifestation, manifestationStatusForUser)
              }
              onClick={handleSubmit}
              value={chosenManifestation?.id}
            >
              {t("Velg")}
            </Button>
          </HStack>
        }
        isOpen={isOpen}
        onClose={() => {
          setChosenManifestation(
            [...manifestationsWithoutPartNumber, ...manifestationsWithPartNumber].find((manifestation) =>
              isManifestationReservable(manifestation, manifestationStatusForUser)
            )
          );
          handleClose();
        }}
      >
        <ModalBody
          {...getRootProps()}
          padding="0"
          display="flex"
          flexDirection="column"
          gap="1rem"
          justifyContent="center"
        >
          {manifestationsWithoutPartNumber.length > 0 && (
            <VStack gap="0.75rem" alignItems="flex-start">
              {manifestationsWithoutPartNumber.map((manifestation) => {
                const radio = getRadioProps(manifestation);
                return (
                  <ChoosableManifestation
                    key={manifestation.id}
                    work={work}
                    manifestation={manifestation}
                    manifestationStatusForUser={manifestationStatusForUser}
                    chosenManifestationId={chosenManifestation?.id}
                    radio={{ ...radio, onChange: () => setChosenManifestation(manifestation) }}
                  />
                );
              })}
            </VStack>
          )}
          {manifestationsWithPartNumber.length > 0 && (
            <Box>
              <FormLabel fontSize="md" marginBottom="0.25rem" fontWeight="semibold">
                {t("Utgaver i flere deler")}
              </FormLabel>
              <VStack gap="0.75rem" alignItems="flex-start">
                {manifestationsWithPartNumber.map((manifestation) => {
                  const radio = getRadioProps({ manifestation });
                  return (
                    <ChoosableManifestation
                      key={manifestation.id}
                      work={work}
                      manifestation={manifestation}
                      manifestationStatusForUser={manifestationStatusForUser}
                      partNumber={manifestation.partNumber}
                      chosenManifestationId={chosenManifestation?.id}
                      radio={{
                        ...radio,
                        onChange: () => setChosenManifestation(manifestation),
                      }}
                    />
                  );
                })}
              </VStack>
            </Box>
          )}
        </ModalBody>
      </ModalContainer>
    </form>
  );
};

const ChoosableManifestation = ({
  work,
  manifestation,
  partNumber,
  chosenManifestationId,
  manifestationStatusForUser,
  radio,
}: {
  work: Work;
  manifestation: SortableManifestation;
  manifestationStatusForUser: ManifestationStatusForUserType;
  partNumber?: string;
  chosenManifestationId: string | undefined;
  radio: RadioProps;
}) => {
  const { t } = useTranslation();

  const { getInputProps, getRadioProps } = useRadio(radio);

  const palette = useSitePalette();

  const manifestationCanBeReserved = isManifestationReservable(manifestation, manifestationStatusForUser);

  return (
    <Box as="label" key={manifestation.id} width="100%">
      <input {...getInputProps()} disabled={!manifestationCanBeReserved} />
      <HStack
        width="100%"
        alignItems="stretch"
        gap="1rem"
        padding="0.5rem"
        borderRadius="lg"
        {...(chosenManifestationId === manifestation.id ? palette.colors.darkaccent3.css : palette.colors.card.css)}
        {...getRadioProps()}
        {...(!manifestationCanBeReserved ? { color: colors.grey60 } : {})}
        _focusVisible={{ outline: "3px solid black", outlineOffset: "-3px" }}
      >
        <WorkCoverImage
          alignSelf="center"
          work={work}
          representativeManifestation={manifestation}
          height="fit-content"
          minWidth="4rem"
          maxWidth="4rem"
          {...(!manifestationCanBeReserved ? { opacity: "60%" } : {})}
        />
        <VStack alignItems="flex-start" justifyContent="space-between" gap="0.5rem">
          <VStack alignItems="flex-start" justifyContent="center" gap="-0.5rem">
            <Heading
              as="h3"
              {...(manifestation.id === chosenManifestationId ? { textDecoration: "underline 2px" } : {})}
              size="md"
            >
              {formatIsbdTitle(manifestation, { skipSubtitle: true })}
            </Heading>
            {partNumber && (
              <Text fontStyle="italic" fontSize="md">
                {isNumber(Number.parseInt(partNumber[0] ?? "")) ? `${t("Del")} ${partNumber}` : partNumber}
              </Text>
            )}
            <Text fontSize="sm">
              {getDotseparated([
                ...manifestation.publicationStatements.flatMap(
                  (publicationStatement) => publicationStatement.imprint.name
                ),
                manifestation.publicationYear,
              ])}
            </Text>
          </VStack>
          {!manifestationCanBeReserved && (
            <Alert status="info" padding="0.25rem" paddingRight="0.5rem" gap="0.25rem" fontSize="smaller">
              <Text fontSize="sm" color={colors.black}>
                {t("Allerede reservert")}
              </Text>
            </Alert>
          )}
          {manifestationCanBeReserved && <HoldingText work={work} manifestationIds={[manifestation.id]} />}
        </VStack>
      </HStack>
    </Box>
  );
};

const isManifestationReservable = (
  manifestation: SortableManifestation,
  manifestationStatusForUser: ManifestationStatusForUserType
) =>
  !(
    manifestationStatusForUser.loanedManifestations?.find(({ id }) => manifestation.id === id) ??
    manifestationStatusForUser.reservedManifestations?.find(({ id }) => manifestation.id === id)
  );
