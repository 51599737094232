import { Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import {
  Box,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Text,
  colors,
} from "@biblioteksentralen/react";
import { Fragment } from "react";
import { ModalContainer } from "../../../components/ModalContainer";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useHoldingLocations } from "../../hooks/useHoldingLocations";
import { LibraryInfo } from "../../sanityQueries";
import { getDotseparated } from "../getDotseparated";
import { TableCellTitle, TableCellValue, TableRow } from "../manifestations/ManifestationViewContributorRows";
import { SortableManifestation } from "../../types";
import { formatLanguagesList, getDocumentTypeLabel } from "@libry-content/integrations";

type FindInLibraryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  work: Work;
  libraries: LibraryInfo[];
  manifestationIds: string[];
  representativeManifestation?: SortableManifestation;
  isRepresentedWork?: boolean;
};

// TODO: Incorporate sublocation when available from API
// TODO: Include info corresponding to useRestrictions
export const FindInLibraryModal = ({
  isOpen,
  onClose,
  representativeManifestation,
  libraries,
  manifestationIds,
  work,
  isRepresentedWork,
}: FindInLibraryModalProps) => {
  const { t } = useTranslation();
  const { locations, isLoading } = useHoldingLocations(work, libraries, manifestationIds);

  const languagesAndDocumentType = getDotseparated([
    representativeManifestation
      ? formatLanguagesList(representativeManifestation?.expression.languages, { capitalizeFirstLetter: true })
      : undefined,
    representativeManifestation?.documentType
      ? getDocumentTypeLabel(representativeManifestation?.documentType)
      : undefined,
  ]);

  if (isLoading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent height="12rem" display="flex" justifyContent="center" alignItems="center">
          <ModalCloseButton marginTop="0.4rem" />
          <Spinner size="lg" />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <ModalContainer heading={t("Finn i biblioteket")} isCentered={true} isOpen={isOpen} onClose={onClose}>
      {representativeManifestation && (
        <Box>
          <Heading as="h3" fontSize="lg" marginBottom="0.25rem">
            {formatIsbdTitle(representativeManifestation)}
          </Heading>
          <Text fontSize="lg" marginBottom={isRepresentedWork ? "1rem" : "0"}>
            {languagesAndDocumentType}
          </Text>

          {!isRepresentedWork && representativeManifestation.publicationYear && (
            <Text fontSize="lg" marginBottom="1rem">
              {t("Utgivelsesår")}: {representativeManifestation.publicationYear}
            </Text>
          )}
        </Box>
      )}
      <Box borderRadius="md" overflowY="auto">
        {Object.entries(locations).map(([section, sectionData = []], indexLibraryLocations) => (
          <Box
            key={indexLibraryLocations}
            width="100%"
            padding="0.75rem 0.75rem 0.25rem 0.75rem"
            background={indexLibraryLocations % 2 ? colors.white : colors.grey05}
          >
            <Text fontWeight="semibold" fontSize="lg">
              {section}
            </Text>
            <Table fontSize="lg" borderColor={colors.black} sx={{ tableLayout: "fixed", width: "100%" }}>
              <Tbody>
                {sectionData.map(
                  ({ totalForLoan, availableForLoan, totalNotForLoan, totalUnavailable, shelfLocation }, index) => (
                    <Fragment key={`${indexLibraryLocations}-${index}`}>
                      {!!shelfLocation && (
                        <TableRow border="none">
                          <TableCellTitle padding="0.5rem 0 0.25rem 0">{t("Hylleplass")}</TableCellTitle>
                          <TableCellValue padding="0.5rem 0 0.25rem 0">{shelfLocation}</TableCellValue>
                        </TableRow>
                      )}
                      {totalForLoan > 0 && (
                        <TableRow
                          borderBottom="2px"
                          borderColor={indexLibraryLocations % 2 ? colors.grey05 : colors.white}
                        >
                          <TableCellTitle padding="0.25rem 0 0.5rem 0">{t("Status")}</TableCellTitle>
                          <TableCellValue padding="0.25rem 0 0.5rem 0">
                            {`${availableForLoan} ${t("av")} ${totalForLoan} ${t("på hylla")}`}
                          </TableCellValue>
                        </TableRow>
                      )}
                      {totalNotForLoan > 0 && (
                        <TableRow
                          borderBottom="2px"
                          borderColor={indexLibraryLocations % 2 ? colors.grey05 : colors.white}
                        >
                          <TableCellTitle padding="0.25rem 0 0.5rem 0">{t("Status")}</TableCellTitle>
                          <TableCellValue padding="0.25rem 0 0.5rem 0">{t("Ikke til hjemlån")}</TableCellValue>
                        </TableRow>
                      )}
                      {totalForLoan == 0 && totalNotForLoan == 0 && totalUnavailable >= 0 && (
                        <TableRow
                          borderBottom="2px"
                          borderColor={indexLibraryLocations % 2 ? colors.grey05 : colors.white}
                        >
                          <TableCellTitle padding="0.25rem 0 0.5rem 0">{t("Status")}</TableCellTitle>
                          <TableCellValue padding="0.25rem 0 0.5rem 0">
                            {t("Ikke tilgjengelig for øyeblikket")}
                          </TableCellValue>
                        </TableRow>
                      )}
                    </Fragment>
                  )
                )}
              </Tbody>
            </Table>
          </Box>
        ))}
      </Box>
    </ModalContainer>
  );
};
