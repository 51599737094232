import { DocumentType, Platform } from "@biblioteksentralen/cordata";
import { LocalizedField } from "@libry-content/localization";

const getDefiniteNoun = (documentType: DocumentType | undefined) => {
  switch (documentType?.format.toLowerCase()) {
    case "film":
      return { nb: "filmen", nn: "filmen" };
    case "dataspill":
      return { nb: "spillet", nn: "spelet" };
    case "musikk":
      return { nb: "musikken", nn: "musikken" };
    case "musikktrykk":
      return { nb: "musikken", nn: "musikken" };
    default:
      return { nb: "boka", nn: "boka" };
  }
};

const getDeterminative = (documentType: DocumentType | undefined) => {
  switch (documentType?.format.toLowerCase()) {
    case "film":
      return { nb: "denne", nn: "denne" };
    case "dataspill":
      return { nb: "dette", nn: "dette" };
    case "musikk":
      return { nb: "denne", nn: "denne" };
    default:
      return { nb: "denne", nn: "denne" };
  }
};

export const getDocumentTypeDefiniteNoun = (
  documentType: DocumentType | undefined,
  { withDeterminative } = { withDeterminative: false }
): LocalizedField<string> =>
  withDeterminative
    ? concatenateLocalizedStrings(undefined, getDeterminative(documentType), getDefiniteNoun(documentType))
    : getDefiniteNoun(documentType);

const concatenateLocalizedStrings = (
  { separator } = { separator: " " },
  ...localizedStrings: LocalizedField<string>[]
): LocalizedField<string> =>
  localizedStrings
    .slice(1)
    .reduce((acc, { nb, nn }) => ({ nb: `${acc.nb}${separator}${nb}`, nn: `${acc.nn}${separator}${nn}` }), {
      nb: localizedStrings[0]?.nb ?? "",
      nn: localizedStrings[0]?.nn ?? "",
    });

export const getDocumentTypeIndefiniteNoun = (documentType: DocumentType | undefined): LocalizedField<string> => {
  switch (documentType?.format.toLowerCase()) {
    case "film":
      return { nb: "film", nn: "film" };
    case "dataspill":
      return { nb: "spill", nn: "spel" };
    //Jeg vet ikke om det er helt innafor å oversette "musikk" og "musikktrykk" til "format", men det er det som gir mest semantisk mening i reservasjonsflyten for nå :-|
    case "musikk":
      return { nb: "musikk", nn: "musikk" };
    case "musikktrykk":
      return { nb: "musikk", nn: "musikk" };
    default:
      return { nb: "bok", nn: "bok" };
  }
};

export const getPlatformsLabel = (platforms: Platform[]): string | undefined =>
  platforms.length ? platforms.map((platform) => platform.abbreviatedName ?? platform.name).join(" & ") : undefined;
